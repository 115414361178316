import {BrowserRouter, Routes, Route} from 'react-router-dom';
import AccountChooser from './components/account-chooser';
import AccountManagement from './components/account-management';
import CookiesPermission from './components/cookies-permission';
import GenericError from './components/generic-error';
import {useBrand} from './components/app-context-provider';
import {useTheme, MainNavigationBar, useScreenSize} from '@telefonica/mistica';
import {VIVO_BR} from './utils/brands';
import * as React from 'react';

import type {Locale} from '@telefonica/mistica';

const VivoLogo = (): JSX.Element => {
    const {isTabletOrSmaller} = useScreenSize();
    const height = isTabletOrSmaller ? 32 : 40;
    const width = (38 / 52) * height;
    return (
        <svg width={width} height={height} viewBox="0 0 38 52" fill="none">
            <path
                fill="#660099"
                d="M19.0279 41.6313C20.0859 41.6926 20.3956 42.255 20.7542 42.685C21.1146 43.115 26.3533 49.4013 26.7097 49.8092C27.0633 50.2215 28.043 50.9772 29.1201 51.1207C30.1956 51.2631 31.376 51.0456 32.1312 50.4997C32.8846 49.9533 33.8417 49.2098 34.1778 48.7141C34.5117 48.2185 35.0813 47.507 34.9764 46.3379C34.8703 45.171 34.6149 44.5606 33.7024 43.5588C32.791 42.558 27.3989 36.2209 26.3843 35.0049C26.3843 35.0049 25.4622 33.9231 25.5959 32.3941C25.7301 30.8624 26.4227 30.1791 27.8811 29.7982C29.3378 29.4196 35.0356 28.0408 35.0356 28.0408C35.0356 28.0408 38.297 26.4964 37.5577 23.4368L37.133 21.872C37.133 21.872 36.423 18.3019 31.7358 19.4075C27.0459 20.5158 24.4403 21.1693 24.4403 21.1693C24.4403 21.1693 22.947 21.3117 22.7332 20.3254C22.5223 19.3385 22.7992 18.829 23.459 18.5541C24.1183 18.2787 28.2815 16.2899 28.8156 10.9138C29.3536 5.53543 24.8509 0.754293 19.0121 0.774716C13.1717 0.796794 8.9459 5.19983 8.96338 9.75797C8.98086 14.3183 11.9377 17.3525 14.2105 18.4851C15.5003 19.1271 15.0875 20.2829 15.0875 20.2829C15.0875 20.2829 14.7779 21.4072 13.4204 21.1081C12.0629 20.8034 5.48765 19.2982 5.48765 19.2982C5.48765 19.2982 1.52183 18.5635 0.564211 22.4786C-0.394533 26.397 1.83483 27.8857 3.4709 28.2544C5.1064 28.6225 10.6395 29.9622 10.6395 29.9622C10.6395 29.9622 12.2857 30.7747 12.35 32.441C12.39 33.4263 12.3917 34.2432 11.3546 35.7109C10.3192 37.178 3.87978 44.6864 3.87978 44.6864C3.87978 44.6864 1.6662 47.3149 4.87349 49.6839C8.08077 52.0535 9.88546 51.5016 11.7894 49.3185C13.6917 47.1361 17.4511 42.5658 17.4511 42.5658C17.4511 42.5658 17.9699 41.5717 19.0279 41.6313Z"
            />
        </svg>
    );
};

const getLang = (locale: Locale): string => {
    const [lang] = locale.split('-');
    return lang;
};

const App = (): JSX.Element => {
    const brand = useBrand();
    const {i18n} = useTheme();
    const lang = getLang(i18n.locale);

    React.useEffect(() => {
        document.documentElement.setAttribute('lang', lang);
    }, [lang]);

    const navBar = <MainNavigationBar sections={[]} logo={brand === VIVO_BR ? <VivoLogo /> : undefined} />;
    return (
        // https://www.w3.org/International/questions/qa-html-language-declarations.en
        // https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/translate
        <div lang={lang} translate="no">
            <BrowserRouter basename={useBrand()}>
                <Routes>
                    <Route path="/account-chooser" element={<AccountChooser navBar={navBar} />} />
                    <Route
                        path="/account-chooser/management"
                        element={<AccountManagement navBar={navBar} />}
                    />
                    <Route path="/permission" element={<CookiesPermission />} />
                    <Route path="/error" element={<GenericError />} />
                    <Route path="*" element={<GenericError />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
