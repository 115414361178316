import {
    initAnalytics as gaInitAnalytics,
    logEvent as gaLogEvent,
    setScreenName as gaSetScreenName,
    gtagApi,
    consoleApi,
} from '@telefonica/google-analytics';

export type ScreenName =
    | 'login_save_cookies'
    | 'login_account_chooser'
    | 'login_account_chooser_management'
    | 'login_account_chooser_management/empty_case'
    | 'login_error_session';

type EventName = 'user_interaction' | 'popup_view' | 'popup_interaction';

export type TrackingEvent = {
    name: EventName;
    [key: string]: unknown;
};

export const trackEvent = (event: TrackingEvent): Promise<void> => gaLogEvent(event);

export const setScreenName = (screenName: ScreenName): Promise<void> => gaSetScreenName(screenName);

const GA_ID = new URLSearchParams(location.search).get('ga');
const debug = false; // set to true to enable logging while developing

export const initAnalytics = (): void => {
    gaInitAnalytics(GA_ID ?? '', {
        api: GA_ID && (process.env.NODE_ENV === 'production' || debug) ? gtagApi : consoleApi,
    });
};
