import type {Token} from '../translations';

type TranslationsMap = {
    [token: string]: {
        [localeKey: string]: string;
    };
};

let locale: string;
let translations: TranslationsMap;

const init = (localeKey: string, translationsMap: TranslationsMap): void => {
    locale = localeKey;
    translations = translationsMap;
};

const RE_CAPTURE_PARAM = /(\d{1,2})\$\w/g;

const applyParams = (text: string, ...params: Array<string | number>): string => {
    if (params) {
        return text.replace(RE_CAPTURE_PARAM, (_, i) => {
            const index = +i - 1;
            if (process.env.NODE_ENV !== 'production') {
                if (params[index] === undefined) {
                    throw Error(
                        `Missing parameter in token "${text}". Parameters: ${JSON.stringify(params)}`
                    );
                }
            }
            return String(params[index] ?? '');
        });
    }
    return text;
};

const translate = (token: Token, ...variables: Array<string | number>): string => {
    if (!locale || !translations) {
        throw Error('Locale or translations not available. You should not call translate() before init()');
    }

    if (!(token in translations)) {
        throw Error(`Translation not found for token "${token}"`);
    }

    const translated = translations?.[token]?.[locale];
    const text = typeof translated === 'string' ? translated : token.replace(/%\([^)]+\)/g, '');

    return applyParams(text, ...variables);
};

const translatePlural = (
    tokenSingular: Token,
    tokenPlural: Token,
    count: number,
    ...variables: Array<string | number>
): string => (count === 1 ? translate(tokenSingular, ...variables) : translate(tokenPlural, ...variables));

export const I18N = {
    translate,
    translatePlural,
    init,
};
