import {Spinner} from '@telefonica/mistica';

const centerStyles: React.CSSProperties = {
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const LoadingScreen = (): JSX.Element => {
    return (
        <div style={centerStyles}>
            <Spinner size={32} />
        </div>
    );
};

export default LoadingScreen;
