import * as React from 'react';
import {ButtonPrimary, InfoFeedbackScreen} from '@telefonica/mistica';
import {redirectToLogin} from '../../api';
import {I18N} from '../../utils/i18n';
import {useCompanyName} from '../app-context-provider';
import {useDocumentTitle} from '../../hooks';

const EmptyCase = (): JSX.Element => {
    const companyName = useCompanyName();
    useDocumentTitle(I18N.translate('Acceso a 1$s', companyName));

    const [isRedirecting, setIsRedirecting] = React.useState(false);

    return (
        <InfoFeedbackScreen
            title={I18N.translate('No hay usuarios disponibles')}
            description={I18N.translate('Vuelve a iniciar sesión para acceder a 1$s', companyName)}
            primaryButton={
                <ButtonPrimary
                    showSpinner={isRedirecting}
                    trackEvent
                    onPress={() => {
                        setIsRedirecting(true);
                        redirectToLogin().finally(() => {
                            setIsRedirecting(false);
                        });
                    }}
                >
                    {I18N.translate('Iniciar sesión')}
                </ButtonPrimary>
            }
        />
    );
};

export default EmptyCase;
