(function (root, factory) {
    // Makes the library compatible with different module loaders

    if (typeof define === 'function' && define.amd) {
        // AMD: Registered as an anonymous module
        define([], factory);

    } else if (typeof module === 'object' && module.exports) {
        // CommonJS: Does not work with strict CommonJS, only CommonJS-like
        // environments that support module.exports
        module.exports = factory();

    } else {
        // Global "baikalAccountChooser" object in the browser (root is window)
        root.baikalAccountChooser = factory();
    }

}(typeof self !== 'undefined' ? self : this, function () {

    var AUTHSERVER_SSO_ENDPOINT = "/sso/login";
    var AUTHSERVER_ACCOUNT_CHOOSER_ENDPOINT = "/sso/tokens";
    var AUTHSERVER_ACCEPT_COOKIE_ENDPOINT = "/sso/remember";
    var AUTHSERVER_LOGOUT_ENDPOINT = "/sso/forget";

    var AUTH_CTX_ID = "t";

    var LOCATION_PARAMS = _getLocationParams()

    // Calls SSO sign-in endpoint of authserver with async XHR and redirect to the returned url if succeeds
    function login(ssoToken, loginHint, cb) {
        var params = {
            "sso_token": ssoToken,
            "login_hint": loginHint,
        };
        params[AUTH_CTX_ID] = LOCATION_PARAMS[AUTH_CTX_ID];

        var body = _formatParams(params, true);
        _send('POST', AUTHSERVER_SSO_ENDPOINT, 'sso-sign-in', body, function(err, json, xhr) {
            if (err) {
                cb(err);
            } else {
                if (!json.target_url) {
                    cb(_getError('sso-sign-in', 'Received invalid response: ' + xhr.responseText));
                } else {
                    // Execute the callback to give a chance to the client to
                    // do something before the location redirect.
                    cb(null, json.target_url);
                    window.location.replace(json.target_url);
                }
            }
        });
    }

    function forget(ssoToken, cb) {
        var url = AUTHSERVER_LOGOUT_ENDPOINT + "/" + ssoToken;
        var params = {};
        params[AUTH_CTX_ID] = LOCATION_PARAMS[AUTH_CTX_ID];
        
        url = url + _formatParams(params);
        _send('GET', url, 'sso-sign-out', null, function(err, json, xhr) {
            if (err) {
                cb(err);
            } else {
                if (!json.status || json.status !== "OK") {
                    cb(_getError('sso-sign-out', 'Received invalid response: ' + xhr.responseText));
                } else {
                    cb(null);
                }
            }
        });
    }

    // Recovers identities associated to the current sso cookies.
    function getSsoTokens(clientId, acrValues, cb) {
        // Legacy: it has to work as getAccountsInfo(acrValues, cb)
        if (arguments.length < 3) {
            cb = acrValues;
            acrValues = clientId;
            clientId = null;
        }

        _callAccountChooserInfo(clientId, acrValues, function (error, result) {
            if (result) {
                cb(null, _filterIdentitiesByAcr(result, acrValues));
            } else {
                cb(error);
            }
        });
    }

    // Helper to extract the authentication parameters from the location url to make easy for clients
    // to get values that they can use in other methods such as the acr_values.
    function getAuthParams() {
        // Not returning LOCATION_PARAMS because this breaks tests in novum side. Changing the URL doesn't change the LOCATION_PARAMS
        return _getLocationParams();
    }

    function remember(value, cb) {
        var params = {
            "accept": JSON.stringify(value)
        }
        params[AUTH_CTX_ID] = LOCATION_PARAMS[AUTH_CTX_ID];

        var body = _formatParams(params, true);
        _send('POST', AUTHSERVER_ACCEPT_COOKIE_ENDPOINT, 'accept-cookie', body, function(err, json, xhr) {
            if (err) {
                cb(err);
            } else {
                if (!json.target_url) {
                    cb(_getError('accept-cookie', 'Received invalid response: ' + xhr.responseText));
                } else {
                    // Execute the callback to give a chance to the client to
                    // do something before the location redirect.
                    cb(null, json.target_url);
                    window.location.replace(json.target_url);
                }
            }
        });
    }

    // Calls account chooser info endpoint of authserver with async XHR
    function _callAccountChooserInfo(clientId, acrValues, cb) {
        var url = AUTHSERVER_ACCOUNT_CHOOSER_ENDPOINT;

        var params = {};
        params[AUTH_CTX_ID] = LOCATION_PARAMS[AUTH_CTX_ID];

        acrValues = acrValues || [];
        if (clientId != null || acrValues.length > 0) {
            params["client_id"] = clientId;
            params["acr_values"] = acrValues.length > 0 ? acrValues.join(" ") : null;
        }
        url = url + _formatParams(params);

        _send('GET', url, 'account-chooser', null, function(err, json) {
            if (err) {
                cb(err);
            } else {
                cb(null, json);
            }
        });
    }

    // Sends asynchronous requests using XHR
    function _send(method, url, name, body, cb) {
        var xhr = new XMLHttpRequest();

        xhr.open(method, url, true);
        // Set content type for POST method
        if (method === 'POST') {
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        }
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) { // XMLHttpRequest.DONE
                if (xhr.status === 0) {
                    cb(_getError(name, 'Request failed'));
                } else if (xhr.status !== 200) {
                    cb(_getError(name, 'Received invalid status: ' + xhr.status + ' (' + xhr.statusText + ')'));
                } else {
                    try {
                        var jsonResp = JSON.parse(xhr.responseText);
                        cb(null, jsonResp, xhr);

                    } catch(e) {
                        cb(_getError(name, 'Received invalid response: ' + e));

                    }
                }
            }
        };

        xhr.send(body)
    }

    function _formatParams(params, isInBody) {
        var queries = [];
        var keys = Object.keys(params);
        for (var idx in keys) {
            var key = keys[idx];
            if (params[key] != null) {
                queries.push(key + "=" + encodeURIComponent(params[key]));
            }
        }

        if (queries.length > 0) {
            if (isInBody) {
                return queries.join("&");
            } else {
                return "?" + queries.join("&");
            }
        } else {
            return "";
        }
    }

    // Standardizes the errors that the API callbacks returns
    function _getError(code, description) {
        return {
            error: code,
            error_description: description,
            // Backward compatibility
            message: "[" + code + "] " + description,
            name: "Error",
            constructor: Error,
        }
    }

    function _filterIdentitiesByAcr(identities, acrValues) {
        acrValues = acrValues || [];
        if (acrValues && acrValues.length > 0) {
            return identities.filter(function (identity) {
                return acrValues.indexOf(identity.acr) !== -1;
            });
        } else {
            return identities;
        }
    }

    function _getLocationParams() {
        // Note: it cannot use URLSearchParams, it's not supported in IE and some not so old browsers
        var query = location.search.substring(1);

        // Split into key/value pairs
        var queries = query.split("&");
        var length = queries.length;

        // Convert the array of strings into an object
        var raw_params = {};
        for (var i = 0; i < length; i++ ) {
            var temp = queries[i].split('=');
            if (temp.length === 2) {
                raw_params[decodeURIComponent(temp[0])] = _decodeQueryParamValue(temp[1]);
            }
        }

        // Only include in the results the query params supported in authentication
        var params = {};
        if (raw_params.client_id) {
            params.client_id = raw_params.client_id
        }
        if (raw_params.client_name) {
            params.client_name = raw_params.client_name
        }
        if (raw_params.login_hint) {
            params.login_hint = raw_params.login_hint
        }

        // The acr_values is returned as expected by 'getAccountsInfo', an array of strings.
        if (raw_params.acr_values) {
            params.acr_values = raw_params.acr_values.split(/,|\s/);
        }

        // Authorize id to continue in with the same authentication
        if (raw_params[AUTH_CTX_ID]) {
            params[AUTH_CTX_ID] = raw_params[AUTH_CTX_ID];
        }

        return params;
    }

    function _decodeQueryParamValue(value) {
        return decodeURIComponent(value.replace(/\+/g, " "));
    }

    return {
        login: login,
        forget: forget,
        getAccountsInfo: getSsoTokens,
        getAuthParams: getAuthParams,
        remember: remember,
        // Deprecated functions
        logout: forget,
        acceptCookie: remember
    }

}));
