import {type IdentityResponse, type AuthenticationContext} from '../api';
import {MOVISTAR_ES, O2_ES, O2_UK, VIVO_BR, type Brand, O2_DE, BLAU_DE} from './brands';

type LoginHintBuilder = (authenticationContextList: Array<AuthenticationContext>) => string | null;

const movesLoginHintBuilder: LoginHintBuilder = (authenticationContextList) => {
    const byType: Map<AuthenticationContext['type'], AuthenticationContext> = new Map();

    for (const authenticationContext of authenticationContextList) {
        byType.set(authenticationContext.type, authenticationContext);
    }

    const authenticationContext = byType.get('phone_number') || byType.get('uid');

    switch (authenticationContext?.type) {
        case 'phone_number':
            return `MSISDN:${authenticationContext.clear_identifier.replace('+', '')}`;
        case 'uid':
        case 'email':
            return `${authenticationContext.type}:${authenticationContext.clear_identifier}`;
        default:
            return null;
    }
};

const nullLoginHintBuilder: LoginHintBuilder = () => null;

const LOGIN_HINT_BUILDER_BY_BRAND: {[brand in Brand]: LoginHintBuilder} = {
    [MOVISTAR_ES]: movesLoginHintBuilder,
    [O2_UK]: nullLoginHintBuilder,
    [O2_ES]: nullLoginHintBuilder,
    [VIVO_BR]: nullLoginHintBuilder,
    [O2_DE]: nullLoginHintBuilder,
    [BLAU_DE]: nullLoginHintBuilder,
};

export const createLoginHint = (
    authenticationContextList: Array<AuthenticationContext>,
    brand: Brand
): string | null => {
    const createHint = LOGIN_HINT_BUILDER_BY_BRAND[brand];
    return createHint(authenticationContextList);
};

type LoginHintMatcher = (identities: IdentityResponse[], loginHint: string) => IdentityResponse | null;

const movesLoginHintMatcher: LoginHintMatcher = (identities: IdentityResponse[], loginHint: string) => {
    const [prefix, identifier] = loginHint.split(':');

    const requiredType = {
        msisdn: 'phone_number',
        username: 'uid',
        nif: 'uid',
        uid: 'uid',
        email: 'email',
    }[prefix.toLowerCase()];

    if (!requiredType) {
        return null;
    }

    const matchesAuthContext = (authenticationContext: AuthenticationContext) => {
        if (authenticationContext.type !== requiredType) {
            return false;
        }
        if (requiredType === 'phone_number') {
            return authenticationContext.clear_identifier.replace('+', '') === identifier;
        }
        return authenticationContext.clear_identifier === identifier;
    };

    for (const identity of identities) {
        if (identity.authentication_context.some(matchesAuthContext)) {
            return identity;
        }
    }

    return null;
};

const nullLoginHintMatcher: LoginHintMatcher = () => null;

const LOGIN_HINT_MATCHER_BY_BRAND: {[brand in Brand]: LoginHintMatcher} = {
    [MOVISTAR_ES]: movesLoginHintMatcher,
    [O2_UK]: nullLoginHintMatcher,
    [O2_ES]: nullLoginHintMatcher,
    [VIVO_BR]: nullLoginHintMatcher,
    [O2_DE]: nullLoginHintMatcher,
    [BLAU_DE]: nullLoginHintMatcher,
};

export const findMatchingIdentityForLoginHint = (
    identities: IdentityResponse[],
    loginHint: string,
    brand: Brand
): IdentityResponse | null => {
    const matcher = LOGIN_HINT_MATCHER_BY_BRAND[brand];
    return matcher(identities, loginHint);
};
