import * as React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {ThemeContextProvider, Stack, Box, getSkinByName, KnownSkinName} from '@telefonica/mistica';
import {
    MOVISTAR_ES,
    O2_UK,
    O2_ES,
    VIVO_BR,
    O2_DE,
    BLAU_DE,
    getLocaleForBrand,
    getPhoneNumberFormattingRegionCodeForBrand,
    createBrand,
} from '../utils/brands';
import translations from '../translations';
import {I18N} from '../utils/i18n';
import {trackEvent, initAnalytics, TrackingEvent} from '../utils/analytics';
import {getCurrentBasePath} from '../utils/url';
import {Link} from 'react-router-dom';
import logoMovistar from '../assets/movistar-logo.svg';
import logoVivo from '../assets/vivo-logo.svg';
import {CLIENT_ID_NOVUM} from '../utils/client-ids';
import {getAuthParams} from '../api';
import GenericError from './generic-error';

import type {Brand} from '../utils/brands';

const brandToCompanyName = {
    [MOVISTAR_ES]: 'Movistar',
    [O2_UK]: 'O2',
    [O2_ES]: 'O2',
    [VIVO_BR]: 'Vivo',
    [O2_DE]: 'O2',
    [BLAU_DE]: 'Blau',
} as const;

type Context = {
    brand: Brand;
    appName: string;
    appIcon?: string;
    companyName: string;
};

const AppContext = React.createContext<Context>({
    brand: MOVISTAR_ES,
    appName: '',
    appIcon: '',
    companyName: '',
});

const MisticaLink = ({
    innerRef,
    ...props
}: React.ComponentProps<typeof Link> & {innerRef?: React.Ref<HTMLAnchorElement>}): JSX.Element => {
    return <Link ref={innerRef} {...props} />;
};

export const getApplicationName = (clientId: string, brand: Brand): string => {
    if (brand !== MOVISTAR_ES) {
        return brandToCompanyName[brand];
    }
    switch (clientId) {
        case CLIENT_ID_NOVUM:
            return 'Mi Movistar';
        default:
            return brandToCompanyName[brand];
    }
};

export const getApplicationIcon = (clientId: string, brand: Brand): string | undefined => {
    switch (brand) {
        case VIVO_BR:
            return logoVivo;
        case MOVISTAR_ES:
            return logoMovistar;
        default:
            return;
    }
};

type Props = {
    children: React.ReactNode;
};

const AppContextProvider = ({children}: Props): JSX.Element => {
    const {current: brandFromPath} = React.useRef<Brand>(getCurrentBasePath() as Brand);
    const clientId = getAuthParams().clientId || '';
    const {current: appName} = React.useRef(getApplicationName(clientId, brandFromPath));
    const {current: appIcon} = React.useRef(getApplicationIcon(clientId, brandFromPath));
    const [isReady, setIsReady] = React.useState(false);

    React.useEffect(() => {
        initAnalytics();
        I18N.init(brandFromPath, translations);
        setIsReady(true);
    }, [brandFromPath]);

    if (!isReady) {
        return <></>;
    }

    const skin: KnownSkinName = brandToCompanyName[brandFromPath];
    if (!skin) {
        if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
            return (
                <ThemeContextProvider
                    theme={{
                        skin: getSkinByName('Movistar'),
                        i18n: {locale: 'es-ES', phoneNumberFormattingRegionCode: 'ES'},
                        colorScheme: 'light',
                    }}
                >
                    <GenericError />
                </ThemeContextProvider>
            );
        } else {
            const cases = [
                [MOVISTAR_ES, CLIENT_ID_NOVUM],
                [O2_UK, CLIENT_ID_NOVUM],
                [O2_ES, CLIENT_ID_NOVUM],
                [VIVO_BR, CLIENT_ID_NOVUM],
                [O2_DE, CLIENT_ID_NOVUM],
                [BLAU_DE, CLIENT_ID_NOVUM],
            ] as const;
            return (
                <Box padding={16}>
                    <Box paddingBottom={16}>
                        <span style={{fontWeight: 'bold'}}>THIS IS ONLY VISIBLE IN DEV</span>
                    </Box>
                    <Box>Missing path, please choose one:</Box>
                    {cases.map(([brand, clientId], index) => {
                        const developmentAnalyticsId = 'G-PWW1VLET5K';
                        const accountChooserUrl = `/${brand}/account-chooser?acr_values=2,3,4&client_id=${clientId}&ga=${developmentAnalyticsId}`;
                        const accountChooserWithLoginHintUrl = `${accountChooserUrl}&login_hint=MSISDN:34678432394&ga=${developmentAnalyticsId}`;
                        const accountChooserManagementUrl = `/${brand}/account-chooser/management?acr_values=2,3,4&client_id=${clientId}&ga=${developmentAnalyticsId}`;
                        const permissionUrl = `/${brand}/permission?client_id=${clientId}&ga=${developmentAnalyticsId}`;
                        return (
                            <Box padding={16} key={index}>
                                <Stack space={8}>
                                    <span style={{fontWeight: 'bold'}}>
                                        {brand} / {getApplicationName(clientId, brand)}
                                    </span>
                                    <a href={accountChooserUrl}>{accountChooserUrl}</a>
                                    <a href={accountChooserWithLoginHintUrl}>
                                        {accountChooserWithLoginHintUrl}
                                    </a>
                                    <a href={accountChooserManagementUrl}>{accountChooserManagementUrl}</a>
                                    <a href={permissionUrl}>{permissionUrl}</a>
                                </Stack>
                            </Box>
                        );
                    })}
                </Box>
            );
        }
    }

    const brand = createBrand(brandFromPath);
    const phoneNumberFormattingRegionCode = getPhoneNumberFormattingRegionCodeForBrand(brand);
    const locale = getLocaleForBrand(brand);
    return (
        <AppContext.Provider value={{brand, appName, appIcon, companyName: brandToCompanyName[brand]}}>
            <ThemeContextProvider
                theme={{
                    skin: getSkinByName(skin),
                    i18n: {locale, phoneNumberFormattingRegionCode},
                    analytics: {
                        logEvent(ev) {
                            return trackEvent(ev as TrackingEvent);
                        },
                        eventFormat: 'google-analytics-4',
                    },
                    Link: MisticaLink,
                    colorScheme: 'light',
                }}
            >
                <ErrorBoundary fallback={<GenericError />}>{children}</ErrorBoundary>
            </ThemeContextProvider>
        </AppContext.Provider>
    );
};

export const useBrand = (): Brand => React.useContext(AppContext).brand;
export const useApplicationName = (): string => React.useContext(AppContext).appName;
export const useApplicationIcon = (): string | undefined => React.useContext(AppContext).appIcon;
export const useCompanyName = (): string => React.useContext(AppContext).companyName;

export default AppContextProvider;
