import * as React from 'react';
import {ErrorFeedbackScreen} from '@telefonica/mistica';
import {I18N} from '../utils/i18n';
import {setScreenName} from '../utils/analytics';

const GenericError = (): React.ReactElement => {
    React.useEffect(() => {
        setScreenName('login_error_session');
    }, []);
    return (
        <ErrorFeedbackScreen
            title={I18N.translate('Algo ha ido mal')}
            description={I18N.translate(
                'Ha ocurrido un error mientras iniciabas sesión. Inténtalo de nuevo más tarde.'
            )}
        />
    );
};

export default GenericError;
