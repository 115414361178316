import {type Brand} from './utils/brands';
import {createLoginHint, findMatchingIdentityForLoginHint} from './utils/login-hint';

const api =
    process.env.NODE_ENV === 'production'
        ? require('@baikal/api-client')
        : require('./baikal-api-client-stub');

export type AuthenticationContext = {
    identifier: string;
    type: 'phone_number' | 'email' | 'uid';
    activated_roles: any[];
    clear_identifier: string;
};

export type IdentityResponse = {
    acr: string;
    last_active_auth: string;
    sso_token: string;
    partial: boolean;
    authentication_context: Array<AuthenticationContext>;
};

export type UserAccount = {
    identifier: string;
    loginHint: string | null;
    ssoToken: string;
    lastActiveTime: number;
    partial: boolean;
};

export const createUserAccount = (identity: IdentityResponse, brand: Brand): UserAccount => ({
    identifier: identity.authentication_context[0].identifier,
    ssoToken: identity.sso_token,
    lastActiveTime: new Date(identity.last_active_auth).getTime(),
    partial: !!identity.partial,
    loginHint: createLoginHint(identity.authentication_context, brand),
});

export const login = (ssoToken: string, loginHint?: string | null): Promise<string> =>
    new Promise((resolve, reject) => {
        api.login(ssoToken, loginHint || null, (error: any, result: string) => {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        });
    });

export const redirectToLogin = (): Promise<string> =>
    new Promise((resolve, reject) => {
        api.login(null, null, (error: any, result: string) => {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        });
    });

export const getAccounts = (
    brand: Brand,
    clientId: string,
    acrValues: Array<string>,
    loginHint?: string
): Promise<Array<UserAccount>> =>
    new Promise((resolve, reject) => {
        api.getAccountsInfo(clientId, acrValues, (error: any, result: IdentityResponse[]) => {
            if (error) {
                reject(error);
            } else {
                if (loginHint) {
                    const matchingIdentity = findMatchingIdentityForLoginHint(result, loginHint, brand);
                    if (matchingIdentity) {
                        resolve([
                            {
                                ...createUserAccount(matchingIdentity, brand),
                                loginHint, // preserve the original loginHint
                            },
                        ]);
                        return;
                    } else {
                        // In case there is no matching identity for the provided loginHint, we redirect to login
                        resolve([]);
                        return;
                    }
                }
                resolve(
                    result
                        .map((identity) => createUserAccount(identity, brand))
                        .sort((a, b) => b.lastActiveTime - a.lastActiveTime)
                );
            }
        });
    });

export const forget = (ssoToken: string): Promise<void> =>
    new Promise((resolve, reject) => {
        api.forget(ssoToken, (error: any, result: any) => {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        });
    });

export const remember = (value: boolean): Promise<string> =>
    new Promise((resolve, reject) => {
        api.remember(value, (error: any, result: string) => {
            if (error) {
                reject(error);
            } else {
                resolve(result);
            }
        });
    });

type AuthParams = {
    acrValues: Array<string>;
    clientId?: string;
    clientName?: string;
    loginHint?: string;
};

let memoizedAuthParams: AuthParams = {acrValues: []};
let prevLocationSearch = '';

/**
 * Returns URL auth params. This is not an API call, simply parses current URL
 *
 * Result is memoized so values can be used as hook dependencies
 */
export const getAuthParams = (): AuthParams => {
    if (prevLocationSearch === window.location.search) {
        return memoizedAuthParams;
    }
    const params = api.getAuthParams();
    const result = {
        acrValues: params.acr_values || [],
        clientId: params.client_id,
        clientName: params.client_name,
        loginHint: params.login_hint,
    };
    memoizedAuthParams = result;
    prevLocationSearch = window.location.search;
    return result;
};
