import * as React from 'react';

export const useDocumentTitle = (title: string): void => {
    React.useEffect(() => {
        const oldTitle = document.title;
        document.title = title;
        return () => {
            document.title = oldTitle;
        };
    }, [title]);
};
