import {createRoot} from 'react-dom/client';
import App from './app';
import AppContextProvider from './components/app-context-provider';

import '@telefonica/mistica/css/reset.css';
import '@telefonica/mistica/css/mistica.css';
import './main.css';

const root = createRoot(document.getElementById('root') as any);

root.render(
    <AppContextProvider>
        <App />
    </AppContextProvider>
);
