import * as React from 'react';
import {
    ButtonPrimary,
    ButtonSecondary,
    InfoFeedbackScreen,
    alert,
    skinVars,
    Stack,
    Text2,
    TextLink,
    Text3,
} from '@telefonica/mistica';
import {remember} from '../../api';
import {I18N} from '../../utils/i18n';
import {useBrand, useCompanyName} from '../app-context-provider';
import {BLAU_DE, MOVISTAR_ES, O2_DE, O2_ES, O2_UK, VIVO_BR} from '../../utils/brands';
import {setScreenName} from '../../utils/analytics';

import type {Brand} from '../../utils/brands';

const moreInfoParagraphsByBrand: {[brand in Brand]: Array<string>} = {
    [MOVISTAR_ES]: [
        'Autenticarte en este paso te permitirá, durante un tiempo determinado, acceder a otras aplicaciones de Movistar de forma ágil manteniendo la sesión abierta.',
        'Movistar podrá utilizar diferentes métodos de identificación (contraseña, móvil o cualquier otro medio). Puedes encontrar más información en los Términos y Condiciones de la app.',
    ],
    [VIVO_BR]: [],
    [O2_UK]: [],
    [O2_ES]: [],
    [O2_DE]: [],
    [BLAU_DE]: [],
};

const CookiesPermission = (): JSX.Element => {
    const [isLoading, setLoading] = React.useState(false);
    const [showMoreInfo, setShowMoreInfo] = React.useState(false);
    const companyName = useCompanyName();
    const brand = useBrand();

    const moreInfoParagraphs = moreInfoParagraphsByBrand[brand];
    const thereIsMoreInfo = moreInfoParagraphs.length > 0;

    const onSaveCookie = (value: boolean): void => {
        setLoading(true);
        remember(value)
            .catch(() => {
                alert({
                    title: I18N.translate('Las cookies están inhabilitadas'),
                    message: I18N.translate(
                        'Tu navegador tiene las cookies y/o javascript inhabilitado. Asegúrate de que esté todo habilitado e inténtelo de nuevo'
                    ),
                    acceptText: I18N.translate('OK'),
                    onAccept: () => {
                        onSaveCookie(value);
                    },
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const acceptButtonText = I18N.translate('Sí, guardar datos');
    const cancelButtonText = I18N.translate('No, gracias');

    React.useEffect(() => {
        setScreenName('login_save_cookies');
    }, []);

    return (
        <InfoFeedbackScreen
            title={I18N.translate(
                '¿Quieres guardar estos datos de acceso para entrar en otras apps de 1$s?',
                companyName
            )}
            description={I18N.translate(
                'Te los recordaremos cuando inicies sesión en nuestras apps y no tendrás que volver a introducir tus claves.'
            )}
            primaryButton={
                <ButtonPrimary disabled={isLoading} trackEvent onPress={() => onSaveCookie(true)}>
                    {acceptButtonText}
                </ButtonPrimary>
            }
            secondaryButton={
                <ButtonSecondary trackEvent disabled={isLoading} onPress={() => onSaveCookie(false)}>
                    {cancelButtonText}
                </ButtonSecondary>
            }
        >
            {thereIsMoreInfo &&
                (showMoreInfo ? (
                    <Stack space={16}>
                        {moreInfoParagraphs.map((p, idx) => (
                            <Text3 key={idx} regular as="p" color={skinVars.colors.textSecondary}>
                                {p}
                            </Text3>
                        ))}
                        <Text2 regular>
                            <TextLink onPress={() => setShowMoreInfo(false)}>
                                {I18N.translate('Menos información')}
                            </TextLink>
                        </Text2>
                    </Stack>
                ) : (
                    <Text2 regular>
                        <TextLink onPress={() => setShowMoreInfo(true)}>
                            {I18N.translate('Más información')}
                        </TextLink>
                    </Text2>
                ))}
        </InfoFeedbackScreen>
    );
};

export default CookiesPermission;
